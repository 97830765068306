import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { paths } from "render/routes/paths";
import { RadioGroup } from "render/ui/presentation/RadioGroup";
import { useMemo, useState } from "react";
import { getAndParseLocationCookie } from "lib/nekoCookies";
import { usePatientQueries } from "hooks/queries/usePatientQuery";
import { useTodaysScheduleQuery } from "hooks/queries/useTodaysScheduleQuery";
import { useVisitQueries } from "hooks/queries/useVisitQuery";
import { APITypesV1 } from "@cur8/api-client";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { useRoomQueries } from "hooks/queries/useRoomQuery";
import { useVisitHistoryQueries } from "hooks/queries/useVisitHistoryQuery";
import { generateVisitTypeLabel } from "lib/generateVisitTypeLabel";

export function SelectActiveVisit({
  goTo,
  onBack,
}: {
  goTo: (url: string) => void;
  onBack(): void;
}) {
  const location = getAndParseLocationCookie();
  const siteId = location?.siteId;

  const scheduleQuery = useTodaysScheduleQuery({ siteId });
  const visitsData = useVisitQueries(scheduleQuery.data);
  const activeVisits = visitsData
    .filter(
      (visit) =>
        visit.data?.status === APITypesV1.VisitStatus.CheckedIn ||
        visit.data?.status === APITypesV1.VisitStatus.Ongoing
    )
    .sort((a, b) => {
      if (a.data == null) {
        return 1;
      }
      if (b.data == null) {
        return -1;
      }
      return a.data.startTime < b.data.startTime ? -1 : 1;
    });
  const patientIds = activeVisits.map((visit) => visit.data?.patientId);
  const patientData = usePatientQueries(patientIds);
  const [selectedVisitId, setSelectedVisitId] = useState<string | undefined>(
    undefined
  );
  const roomIds = activeVisits.map((visit) => visit.data?.roomId);
  const roomQueries = useRoomQueries(roomIds);

  const visitHistoryData = useVisitHistoryQueries(patientIds);

  const selectedVisit = useMemo(() => {
    return activeVisits.find(
      (visit) => visit.data?.visitId === selectedVisitId
    );
  }, [selectedVisitId, activeVisits]);

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <div className={styles.title}>
              <Typography variant="display-s">Active sessions</Typography>
              <Typography variant="body-m" color="subtle">
                Select the member whose session you'd like to restore.
              </Typography>
            </div>
            {activeVisits.length === 0 ? (
              <Typography variant="body-m">No active sessions</Typography>
            ) : undefined}
            <RadioGroup
              aria-label="select visit"
              onChange={(value: string) => setSelectedVisitId(value)}
            >
              {activeVisits.map(({ data: visit }, index) => {
                if (visit?.visitId == null) {
                  return undefined;
                }
                const member = patientData.at(index)?.data;
                const fullName = `${member?.name?.firstName} ${member?.name?.lastName}`;
                const time = (
                  <Typography
                    variant="body-m"
                    as="span"
                    color="subtle"
                    whiteSpace="pre"
                  >
                    {visit?.startTime.toFormat("HH:mm")} -
                    {visit?.endTime.toFormat("HH:mm")}
                  </Typography>
                );
                const room = roomQueries.at(index)?.data?.displayName;

                const visitHistory = visitHistoryData.at(index)?.data;
                const visitTypeLabel = (
                  <Typography variant="body-m" as="span" color="subtle">
                    {visitHistory != null
                      ? generateVisitTypeLabel({ visit, visitHistory })
                      : undefined}
                  </Typography>
                );

                return (
                  <RadioGroup.Radio
                    key={visit.visitId}
                    value={visit?.visitId}
                    label={fullName}
                    bottomLeft={visitTypeLabel}
                    topRight={room}
                    bottomRight={time}
                  />
                );
              })}
            </RadioGroup>
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                disabled={!selectedVisit}
                onClick={() => {
                  if (!selectedVisit?.data) {
                    return;
                  }
                  goTo(
                    paths.selectVisitConfirm.url({
                      visitId: selectedVisit.data.visitId,
                      patientId: selectedVisit.data.patientId,
                    })
                  );
                }}
                variant="suggestion"
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
