import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useUpdateCheckInSessionMutation() {
  const apiClient = useAPIClient();
  const { logError } = useReporting();

  return useMutation({
    mutationFn({ sessionId, visitId }: { sessionId: string; visitId: string }) {
      return apiClient.checkinSession.updateSession(
        { sessionId },
        { conciergeState: visitId }
      ).result;
    },
    onError: (error) => logError(error),
  });
}
