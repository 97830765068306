import { APITypesV1 } from "@cur8/api-client";
import { Visit, VisitHistorySummary } from "@cur8/rich-entity";

export function generateVisitTypeLabel({
  visit,
  visitHistory,
}: {
  visit: Visit;
  visitHistory: VisitHistorySummary;
}) {
  const isBodyScan = visit.medicalExams?.includes(
    APITypesV1.MedicalExam.NekoBodyScan
  );

  if (isBodyScan) {
    const alreadyCheckedIn = visitHistory.attendedVisitIds.includes(
      visit.visitId
    );
    const bodyScanCount =
      visitHistory.medicalExamVisitTimeSeries.nekoBodyScan?.length ?? 0;
    const finalCount = alreadyCheckedIn ? bodyScanCount : bodyScanCount + 1;

    return `Body Scan [${finalCount}]`;
  }

  const followUpExams = visit.medicalExams?.join(", ");

  return `Follow-up ${followUpExams}`;
}
