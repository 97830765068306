import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";

interface PatientDetails {
  name?: string;
  visitLabel: string;
}

export function PatientDetails({ name, visitLabel }: PatientDetails) {
  return (
    <>
      {name && visitLabel && (
        <div className={styles.PatientDetails}>
          <Typography variant="label-s">{name}</Typography>
          <Typography variant="body-s" color="subtle">
            {visitLabel}
          </Typography>
        </div>
      )}
    </>
  );
}
