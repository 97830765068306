import { fromAPI } from "@cur8/rich-entity";
import { useQueries, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export function useVisitHistoryQueries(
  data: Array<string | undefined> | undefined
) {
  const apiClient = useAPIClient();

  return useQueries({
    queries: data
      ? data.map((patientId) => {
          return {
            queryKey: queryKey(patientId),
            queryFn: queryFn(apiClient, patientId),
          };
        })
      : [],
  });
}

export function useVisitHistoryQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: queryFn(apiClient, patientId),
    ...options,
  });
}

function queryFn(apiClient: APIClient, patientId: string | undefined) {
  return async function () {
    if (patientId == null) {
      return Promise.reject("patientId is undefined");
    }
    const visitHistoryDTO = await apiClient.visit.fetchVisitHistory({
      patientId,
    }).result;

    return fromAPI.toVisitHistory(visitHistoryDTO);
  };
}

function queryKey(patientId: string | undefined) {
  return ["visit-history", patientId];
}

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;
