import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";

export function useVisitToCheckInQuery({
  patientId,
  origin,
}: {
  patientId: string | undefined;
  origin: DateTime;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: patientId ? queryFn(apiClient, patientId, origin) : skipToken,
  });
}

function queryFn(apiClient: APIClient, patientId: string, origin: DateTime) {
  return async function () {
    const range = {
      start: origin.startOf("day"),
      end: origin.endOf("day"),
    };

    const startTime = range.start.toISO();
    const endTime = range.end.toISO();
    if (!startTime || !endTime) {
      throw new Error("Invalid Dates");
    }

    const visitDTO = await apiClient.visit.getPatientVisits(
      {
        patientId,
      },
      {
        pageSize: 1,
        startDateTimeOffsetRange: {
          start: startTime,
          end: endTime,
        },
        status: [APITypesV1.VisitStatus.Scheduled],
      }
    ).result;

    return visitDTO.items.map(fromAPI.toVisit).at(0);
  };
}

function queryKey(patientId: string | undefined) {
  return ["visitToCheckIn", patientId] as const;
}
