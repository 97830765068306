import { useNav, useLocation } from "@pomle/react-router-paths";
import { useEffect, useMemo } from "react";
import { ReactComponent as ArrowIcon } from "render/assets/icons/arrow2.svg";
import { paths } from "render/routes/paths";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import { PatientDetails } from "./PatientDetails";
import styles from "./styles.module.sass";
import { usePatientQuery } from "hooks/queries/usePatientQuery";
import { useVisitSummariesQuery } from "hooks/queries/useVisitSummaries";
import { useReporting } from "render/hooks/useReporting";
import { useVisitHistoryQuery } from "hooks/queries/useVisitHistoryQuery";
import { useVisitQuery } from "hooks/queries/useVisitQuery";
import { APITypesV1 } from "@cur8/api-client";

export const HeaderRightSideContent = () => {
  const { logError } = useReporting();
  const { pathname } = useLocation();

  const params = useMemo(() => {
    return paths.checkIn.parse(pathname);
  }, [pathname]);

  const { data: patient } = usePatientQuery({
    patientId: params?.patientId,
    options: {
      enabled: Boolean(params?.patientId),
    },
  });

  const { data: visitHistory } = useVisitHistoryQuery({
    patientId: params?.patientId,
    options: {
      enabled: Boolean(params?.patientId),
    },
  });

  const { data: visit } = useVisitQuery({
    patientId: params?.patientId,
    visitId: params?.visitId,
  });

  const visitLabel = useMemo(() => {
    if (visit?.medicalExams?.includes(APITypesV1.MedicalExam.NekoBodyScan)) {
      const index =
        visitHistory?.medicalExamVisitTimeSeries.nekoBodyScan?.findIndex(
          (visit) => visit.visitId === params?.visitId
        );
      const length =
        visitHistory?.medicalExamVisitTimeSeries.nekoBodyScan?.length;

      const count = index != null && length != null ? length - index : 1;
      return `Body Scan ${count}`;
    }

    if (visit?.medicalExams && visit.medicalExams.length > 0) {
      return "Follow-up";
    }

    return "Unknown";
  }, [params?.visitId, visit?.medicalExams, visitHistory]);

  const { data: visits, error } = useVisitSummariesQuery({
    patientId: params?.patientId,
    options: { enabled: Boolean(params?.patientId) },
  });

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error, logError]);

  const name = patient?.name?.displayName;

  const nav = {
    checkOut: useNav(paths.checkOut),
  };

  return (
    <div className={styles.HeaderRightSideContent}>
      {patient && visits && params ? (
        <>
          <PatientDetails name={name} visitLabel={visitLabel} />
          <DropdownMenuButton>
            <DropdownItem
              icon={<ArrowIcon />}
              onClick={() =>
                nav.checkOut.go({
                  patientId: params.patientId,
                  visitId: params.visitId,
                })
              }
            >
              Check out
            </DropdownItem>
          </DropdownMenuButton>
        </>
      ) : undefined}
    </div>
  );
};
